/*installing tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bggrad{
  background-image: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.courses{
  background-position: center ;
  background-color: #FFD523;
  background-repeat: no-repeat;
}

.swiper {
  width: 100%;

}

.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}


.scroll{
  scroll-behavior: smooth;
  &:hover{
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    opacity: 0;
    visibility: hidden;
  }
  
  &::-webkit-scrollbar-track {
    @apply bg-white invisible

  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    visibility: hidden;
    &:hover{
      @apply bg-sub-b 
    }
    @apply bg-main-b transition-colors duration-300
  }
  

}



.modlar{
  animation: showing 0.2s linear;

}



.modlarwrapper{

  @apply w-96 h-96 overflow-hidden flex flex-wrap rounded-lg flex-row content-start bg-main-b z-20 text-white p-4
}

@keyframes showing {
  0%{
    opacity: 0;
    bottom: -400px;
  }
  100%{
    opacity: 100;
    bottom: 0;
  }
  
}


@media screen and (min-width: 900px) {
  .modlarwrapper{
    width: 30rem;
    height: 30rem;
  }
}